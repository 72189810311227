import Image from "next/image";
import Link from "next/link";

import logo from "../../public/images/logo/logo.svg";
import logoLight from "../../public/images/dark/logo/logo-light.png";

import CopyRight from "./CopyRight";

import FooterData from "../../data/footer.json";
import SingleFooter from "./FooterProps/SingleFooter";
import { useAppContext } from "@/context/Context";
import { useEffect, useState } from "react"; // Import useEffect and useState

const FooterOne = ({ bgColor }) => {
  const { isLightTheme } = useAppContext();
  const [isMounted, setIsMounted] = useState(false); // Track if the component has mounted

  // This will run only after the component is mounted on the client side
  useEffect(() => {
    setIsMounted(true); // Set to true after the component mounts
  }, []);

  // Return null or an empty div during SSR to prevent hydration errors
  if (!isMounted) return null;

  return (
    <>
      <footer
        className={`rbt-footer footer-style-1 ${
          bgColor ? bgColor : "bg-color-white"
        } overflow-hidden`}
      >
        <div className="footer-top">
          <div className="container">
            {FooterData &&
              FooterData.footerTwo.map((footer, index) => (
                <div className="row g-5" key={index}>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="footer-widget">
                      <div className="logo">
                        <Link href="/">
                          {isLightTheme ? (
                            <Image
                              src={logo}
                              width={152}
                              height={50}
                              priority={true}
                              alt="Education Logo Images"
                            />
                          ) : (
                            <Image
                              src={logoLight}
                              width={152}
                              height={50}
                              priority={true}
                              alt="Education Logo Images"
                            />
                          )}
                        </Link>
                      </div>

                      <p className="description mt--20">{footer.description}</p>

                      <ul className="social-icon social-default justify-content-start">
                        {footer.socialLink.map((value, innerIndex) => (
                          <li key={innerIndex}>
                            <Link href={value.link}>
                              <i className={value.icon}></i>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="footer-widget" style={{ marginTop: "20px" }}>
                      <h5 className="ft-title">Get Contact</h5>
                      <ul className="ft-link">
                        <li>
                          <span>Phone: </span> {footer.phone}
                        </li>
                        <li>
                          <span>E-mail:</span>{" "}
                          <Link href="mailto:support@breatheinwidsom.com">
                            {footer.mail}
                          </Link>
                        </li>
                        <li>
                          <span>Address:</span> TECHHELPMENT LLC 74 E Glenwood Ave # 327 Smyrna, DE 19977
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <CopyRight />
      </footer>
    </>
  );
};

export default FooterOne;
