import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import ApiService from "@/utils/ApiServices";
import ApiUrl from "@/utils/ApiUrl";
import { loginAction } from "@/redux/action/AuthAction";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { useState } from "react";
import Cookies from 'js-cookie';

export default function GoogleLoginButton() {
  const dispatch = useDispatch()
  const router = useRouter()
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (userInfo) => {
    dispatch({ type: "AUTH_REQUEST" });
    const userData = {
      email: userInfo.email,
      firstName: userInfo.name,
      lastName: userInfo.name,
      userType: 'google',
      roleId: 3
    }

    try {
      const redirect = router.query.redirect || '/';
      const response = await ApiService.postApiService(ApiUrl.LOGIN_URL, userData);
      if (response.status === 'success') {
        const { user, token } = response.data;
        Cookies.set("userInfo", JSON.stringify(response.data));
        dispatch(loginAction(user, token));
        toast.success(response.message || 'Registration successful! Redirecting to course page...', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // router.push(redirect);
        if (window.history.length > 2 ) {
          router.back();
        } else {
          router.push(redirect);
        }
      }
    } catch (error) {
      const errorMsg = error.response?.data?.message || "Login failed";
      setErrorMessage(errorMsg);
      dispatch({
        type: "AUTH_FAILURE",
        payload: errorMsg,
      });
    }
  };

  const handleSuccess = (response) => {
     if (response.credential) {
      const userInfo = jwtDecode(response.credential);
       handleSubmit(userInfo)
    } else {
      console.error("No credential in the response");
    }
  };

  const handleError = () => {
    console.error('Google login failed');
  };

  return (
    <div className="d-flex justify-content-center">
      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
          theme="outline"
          text="signin_with"
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className="btn btn-info btn-lg d-flex align-items-center justify-content-center w-100"
            >
              <i className="bi bi-google me-2"></i>
              Sign in with Google
            </button>
          )}
        />
      </GoogleOAuthProvider>

    </div>
  );
}
