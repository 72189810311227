import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

// const getAccessToken = () => {
//   const userInfo = Cookies.get('userInfo') || '{}';;
//   if (!userInfo) {
//     console.error('No user info found. User is not authenticated.');
//     return null;
//   }
//   try {
//     let parsedUserInfo=null
//      parsedUserInfo = JSON.parse(userInfo);
//     return parsedUserInfo?.token || null;
//   } catch (error) {
//     console.error('Invalid userInfo in cookies. Parsing error:', error);
//     return null;
//   }
// };

const getAccessToken = (req = null) => {
  if (req) {
    // On SSR (Server-Side Rendering), fetch cookies from request headers
    const cookies = req.headers.cookie || '';
    const userInfoCookie = cookies.split(';').find((cookie) => cookie.trim().startsWith('userInfo='));
    if (userInfoCookie) {
      try {
        const userInfo = JSON.parse(decodeURIComponent(userInfoCookie.split('=')[1]));
        return userInfo?.token || null;
      } catch (error) {
        console.error('Invalid userInfo cookie:', error);
        return null;
      }
    }
  } else {
    // On Client-Side, fetch cookies from js-cookie
    const userInfo = Cookies.get('userInfo') || '{}';
    try {
      const parsedUserInfo = JSON.parse(userInfo);
      return parsedUserInfo?.token || null;
    } catch (error) {
      console.error('Invalid userInfo in cookies:', error);
      return null;
    }
  }
  return null; // Default return if no token is found
};


const ApiService = {

  async getApiService(url, req = null) {
    const accessToken = getAccessToken(req);
    try {
 
        const headers = {'Authorization': `Bearer ${accessToken}`};
        const response = await axios.get(url,{headers});
 
        return response.data;
    } catch (error) {
        handleError(error);
    }
},

  async getApiDetails(url) {
    const accessToken = getAccessToken();
    try {
      const headers = {'Authorization': `Bearer ${accessToken}`};
      const response = await axios.get(url,{headers});
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },


  async postApiService(url, data) {
    const accessToken = getAccessToken();
  try {
    const headers = {'Authorization': `Bearer ${accessToken}`};
    const response = await axios.post(url, data, {headers});
    return response.data;
  } catch (error) {
    handleError(error);
  }
},

  async putApiService(url, data) {
    const accessToken = getAccessToken();
    try {
      // console.log(data, url, config)
      const sharedSecret = process.env.NEXT_PUBLIC_GATEWAY_SHARED_SECRET;
      const headers = {
        'x-shared-secret': sharedSecret,
        'Authorization': `Bearer ${accessToken}`,
      };
      const response = await axios.put(url, data, {headers});
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  async deleteApiService(url) {
    const accessToken = getAccessToken();
    try {
      const headers = {'Authorization': `Bearer ${accessToken}`};
      const response = await axios.delete(url,{headers});
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
};


const handleError = (error) => {
  if (!error.response) {
    console.error('Network error:', error);
    throw new Error('Network error. Please try again later.');
  }
  const { status, data } = error.response;
  const excludedRoutes = ['/login', '/signup', '/','/all-programs','/offers'];
  const currentPath = window.location.pathname;
  const dynamicRoutes = ['/course-details'];
  const isExcludedRoute =
    excludedRoutes.includes(currentPath) ||
    dynamicRoutes.some((route) => currentPath.startsWith(route));
  switch (status) {
    case 404:
      console.error('Not Found:', data.message || 'The requested resource was not found.');
      throw new Error('Resource not found.');
      case 401:
        if (!isExcludedRoute) {
          Cookies.remove('userInfo');
           console.error('Unauthorized:', data.message || 'Access Denied');
          toast.error(data.message || 'Token Seassion Expired');
          window.location.href = '/login';
        } else {
          console.error('Unauthorized:', data.message || 'Access Denied');
          throw new Error('Unauthorized access.');
        }
        break;
    case 403:
      console.error('Forbidden:', data.message || 'You do not have permission to access this resource.');
      throw new Error('Access forbidden.');
    case 500:
      console.error('Server Error:', data.message || 'An unexpected error occurred on the server.');
      throw new Error('Server error. Please try again later.');
    default:
      console.error('Error:', data.message || 'An unexpected error occurred.');
      throw new Error(data.message || 'An unexpected error occurred.');
  }
};
export default ApiService;