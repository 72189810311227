import { useRouter } from "next/router";
import { useState, useEffect } from "react";

import HeaderTopEight from "./Header-Top/HeaderTop-Eight";
import HeaderTopBar from "./HeaderTopBar/HeaderTopBar";
import HeaderEight from "./Headers/Header-Eight";
import DarkSwitch from "./dark-switch";
import { useAppContext } from "@/context/Context";

const HeaderStyleTen = ({ headerSticky, isUrl }) => {
  const { isLightTheme, toggleTheme, mobile, setMobile } = useAppContext();
  const router = useRouter();
  
  // State to track if the component is mounted on the client
  const [isMounted, setIsMounted] = useState(false);

  // UseEffect to mark the component as mounted after the client has rendered
  useEffect(() => {
    setIsMounted(true); // Set to true after the component has mounted
  }, []);

  // Prevent SSR mismatch: Render only on the client
  if (!isMounted) return null;

  return (
    <header className="rbt-header rbt-header-10">
      {/* Check for specific route using the router */}
      {(router.pathname === "/01-main-demo" || router.pathname === "/16-udemy-affiliate") && (
        <HeaderTopBar />
      )}

      <HeaderEight
        isUrl={isUrl}
        mobile={mobile}
        setMobile={setMobile}
        headerSticky={headerSticky}
        sticky="header-sticky"
        container="container-fluid"
        gapSpaceBetween="header-space-betwween"
        navigationEnd="rbt-navigation-start"
      />
    </header>
  );
};

export default HeaderStyleTen;
