import Image from "next/image";
import Link from "next/link";
import logo from "../../public/images/logo/logo.svg";
import logoLight from "../../public/images/dark/logo/logo-light.png";
import Cookies from "js-cookie";
import Nav from "./Nav";
import { useAppContext } from "@/context/Context";
import { logoutAction } from "@/redux/action/AuthAction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";

const MobileMenu = () => {
  const { mobile, setMobile, isLightTheme } = useAppContext();
  const [isMounted, setIsMounted] = useState(false); // State to track if the component has mounted
  let parsedUserInfo = null;
  const userInfo = Cookies.get('userInfo') || '{}';
  parsedUserInfo = JSON.parse(userInfo);
  const dispatch = useDispatch();
  const router = useRouter();

  // Handle logout
  const handleLogout = () => {
    dispatch(logoutAction());
    Cookies.remove("userInfo");

    toast.success('Logout successful', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    router.push("/login"); // Redirect to login page after logout
  };

  // UseEffect to set isMounted to true after the component has mounted on the client
  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Prevent server-side rendering mismatch by returning null until the client has mounted
  if (!isMounted) return null;

  return (
    <div className={`popup-mobile-menu ${mobile ? "" : "active"}`}>
      <div className="inner-wrapper">
        <div className="inner-top">
          <div className="content">
            <div className="logo">
              <Link href="/">
                {isLightTheme ? (
                  <Image
                    src={logo}
                    width={152}
                    height={50}
                    priority={true}
                    alt="Education Logo Images"
                  />
                ) : (
                  <Image
                    src={logoLight}
                    width={152}
                    height={50}
                    priority={true}
                    alt="Education Logo Images"
                  />
                )}
              </Link>
            </div>
            <div className="rbt-btn-close">
              <button
                className="close-button rbt-round-btn"
                onClick={() => setMobile(!mobile)}
              >
                <i className="feather-x"></i>
              </button>
            </div>
          </div>
          <p className="description">
            Unlock personal growth with BreatheInWisdom’s programs. Master mindfulness, meditation, and manifestation for a balanced, fulfilling, and empowered life.
          </p>
          <ul className="navbar-top-left rbt-information-list justify-content-start">
            <li>
              <Link href="mailto:support@breatheinwidsom.com">
                <i className="feather-mail"></i>support@breatheinwidsom.com
              </Link>
            </li>
            <li>
              <span style={{ fontSize: '14px' }}>
                <i className="feather-phone"></i>+1-669-326-8256
              </span>
            </li>
          </ul>
        </div>

        <Nav setMobile={setMobile} mobile={mobile} />

        <div className="mobile-menu-bottom">
          <div className="rbt-btn-wrapper mb--20">
            {!parsedUserInfo?.user ? (
              <Link
                className="rbt-btn btn-border-gradient radius-round btn-sm hover-transform-none w-100 justify-content-center text-center"
                href="/login"
                onClick={() => setMobile(!mobile)}
              >
                <span>Login</span>
              </Link>
            ) : (
              <button
                className="rbt-btn btn-gradient radius-round btn-sm hover-transform-none w-100 justify-content-center text-center"
                onClick={() => {
                  handleLogout();
                  setMobile(!mobile);
                }}
              >
                <span>Logout</span>
              </button>
            )}
          </div>

          <div className="social-share-wrapper">
            <span className="rbt-short-title d-block">Find With Us</span>
            <ul className="social-icon social-default transparent-with-border justify-content-start mt--20">
              <li>
                <Link href="https://www.facebook.com/breatheinwisdom">
                  <i className="feather-facebook"></i>
                </Link>
              </li>
              <li>
                <Link href="https://www.instagram.com/breatheinwisdom/">
                  <i className="feather-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
