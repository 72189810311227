import React, { useEffect, useState } from "react";
import Spinner from "../Spinner/Spinner";
import CourseFilterOneToggle from "../Category/Filter/CourseFilterOneToggle";

const CourseSchool = ({allPrograms}) => {
  return (
    <div className="rbt-banner-area rbt-banner-2 header-transperent-spacer">
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-content text-center">
                <div className="inner">
                  <h1 className="title">
                    Discover the path to{" "}
                    <span className="theme-gradient">
                      purpose, fulfillment
                    </span>{" "}
                    and{" "}
                    <span className="theme-gradient">inner harmony.</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          {allPrograms?.length > 0 ? (
        <CourseFilterOneToggle course={allPrograms} />
      ) : (
        <p>No courses available.</p>
      )}
        </div>
      </div>
    </div>
  );
};

export default CourseSchool;
