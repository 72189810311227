import HomePage from "./02-course-school";
import BackToTop from "./backToTop";
import ApiService from "@/utils/ApiServices";
import ApiUrl from "@/utils/ApiUrl";
import PageHead from './Head'

export default function Home({ allPrograms }) {
  return (
    <main>
      <PageHead title="" />
      <HomePage allPrograms={allPrograms} />
      <BackToTop />
    </main>
  );
}

export const getServerSideProps = async (context) => {
  try {
    const userInfo = context.req.cookies.userInfo || '{}';
    const parsedUserInfo = JSON.parse(userInfo);
    const response = await ApiService.getApiService(ApiUrl.GET_ALL_PROGRAMS, context.req);
    const allPrograms = response?.results || [];

    let paymentStatus = [];

    if (parsedUserInfo?.user?.id) {
       const paymentResponse = await ApiService.getApiService(`${ApiUrl.GET_PAYMENT_STATUS}?user_id=${parsedUserInfo.user.id}`,context.req
      );
       paymentStatus = paymentResponse?.results?.courseIds || [];
    }  
    const updatedPrograms = allPrograms.map((program) => ({
      ...program,
      isPayment: paymentStatus.includes(program._id),
    }));

    return { props: { allPrograms: updatedPrograms } };
  } catch (error) {
     return { props: { allPrograms: [] } };
  }
};
