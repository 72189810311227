// -----------------> Sign Up Action
export const signUpAction = (userInfo, token) => (dispatch) => {
  try {
    // Dispatch the request action to set loading state
    dispatch({ type: "AUTH_REQUEST" });

    // Dispatch the success action with the user data and token
    dispatch({
      type: "AUTH_SUCCESS",
      payload: { userInfo, token },
    });
  } catch (error) {
    // Dispatch failure action if an error occurs
    dispatch({
      type: "AUTH_FAILURE",
      payload: error.message || "Sign up failed",
    });
  }
};

// -----------------> Log In Action
export const loginAction = (userInfo, token) => (dispatch) => {
  try {
     // Dispatch the request action to set loading state
    dispatch({ type: "AUTH_REQUEST" });

    // Dispatch the success action with the user data and token
    dispatch({
      type: "AUTH_SUCCESS",
      payload: { userInfo, token },
    });
  } catch (error) {
    // Dispatch failure action if an error occurs
    dispatch({
      type: "AUTH_FAILURE",
      payload: error.message || "Login failed",
    });
  }
};

// -----------------> Log Out Action
export const logoutAction = () => (dispatch) => {
  try {
    // Dispatch logout action to clear auth state and localStorage
    dispatch({ type: "LOGOUT" });
  } catch (error) {
    console.log("Error during logout:", error);
  }
};

// -----------------> Refresh Token Action
export const refreshTokenAction = (newToken) => (dispatch) => {
  try {
    // Dispatch token refresh action to update token in state and localStorage
    dispatch({
      type: "REFRESH_TOKEN",
      payload: newToken,
    });
  } catch (error) {
    // Dispatch error action if token refresh fails
    dispatch({
      type: "AUTH_ERROR",
      payload: error.message || "Token refresh failed",
    });
  }
};

// -----------------> Clear Auth Error Action
export const clearAuthError = () => (dispatch) => {
  // Dispatch action to clear authentication error messages
  dispatch({
    type: "CLEAR_AUTH_ERROR",
  });
};
