import PageHead from "../Head";
import { Provider } from "react-redux";
import Store from "@/redux/store";
import Context from "@/context/Context";
import CourseSchool from "@/components/02-course-school/CourseSchool";
const CourseSchoolPage = ({ allPrograms }) => {
  return (
    <>
    <CourseSchool allPrograms={allPrograms} />
    </>
  );
};

export default CourseSchoolPage;

